<template>
  <div class="p-10">
    <img
      src="https://playda.s3.ap-south-1.amazonaws.com/gamer/error_404.webp"
      width="320"
      height="588"
    />
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style scoped></style>
