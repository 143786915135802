export const config = {
  url: process.env.VUE_APP_API_URL,
  env: process.env.NODE_ENV,
};

export const apiResource = {
  getBasicNumbers: "/xFJ3JZx-jxh_SzB7DbEUf-JNjiT1rjM-uO3Fk2Xp3JQ=",
  sendContactUsEmail: "/_EEuNZfUjP0ESRvQlxTmDUBU04W6m7pDrQbhss69hRc=",
  addRouterLog: "/6bvHCzJuNewrGAvXeIweAyp17h-kQVDtJ1zcU6gnN2Y=",
  saveWebPushSid: "/gEsbjJfp3eYiwJySQGaZ8hUew-38Eei5ACHVfxgXVuk=",
  updateAllowNotifications: "/9zc2v2gQSvNcEVp_pf6vT8myXpxyKSmNrqjJnZYzvjE=",
  checkBusinessSlug: "/v1b_F83EXVLqzdvdi9T_AWk2dqwdhA3h3nZq2f2A0pE=",
  validateCustomer: "/7BUXsewaL2PSnh2jeWQ4CgccY0_F-_AbvafW3TLcf_Q=",
  validateOTP: "/6DQ2cycjBTd2T6LiCOI4DiTiq7v6XBi5peGW81XG998=",
  resendOTP: "/02QipOr9fLXgjyjd7xqL54gtKGEERggJtZf-d-I4Wf4=",
  profile: "/l9uQCVnZVEF4iWWM73ZoGaMomq6E6rWQN4GOt4FNzs4=",
  getCurrentTime: "/3thLTbEbiIQdMUucrjVsStiTMUwi3iKP0FcvzR3S1ik=",
  getLobbyDetails: "/aVm2fm78FnCJRHFImTCUql1WP2Bfyz2iDW_9vGWaX50=",
  getGameDetails: "/6AYMJX2avRUWpd3qBN9Rk-DBorfWwzeA_w4GMmSBWbw=",
  updatePlayGameStatus: "/AYCTMaGLQbwgRvDak1PsEEL7Ird863CTF4DVuRIaNAU=",
  getLeaderboard: "/Hd7Adigp4UQCkiDEPUV5UdzRzWyACYfnfj9seA5vBnI=",
  saveScavengerHuntScore: "/-y_og33mZfiDEZKQWfunZtyxhkhtibs8AaVzGwFtV98=",
  saveJigsawScore: "/di-rt3_TtzrbuAJTEle7C-9vwTWS2kklAB9ZbgRzDC0=",
  saveTrueFalseScore: "/ll5tSHwxYAnfGDJlHxFjz5o3Gc5avhNZjAtsovNaIZQ=",
  saveGTMScore: "/kWgRbJcwxNw81Fbsp7kp0_smTP9JK4yZL78n46LTD5k=",
  saveWhatCameFirstScore: "/qktCZmK8BP6G88l4dkJlF6M3hGgI2GXXPPjnJ9odq94=",
  saveFindMeScore: "/4FgKpcKk2m3m2zbqeZ9M-VV5uZJEFRNBEXjOe5VfepA=",
  saveMergeFacesScore: "/hF6z-khSocwZcV7B-7VSokcKTjoe4EnpSTzBiqFOQvY=",
  saveMemorizeScore: "/EsoGDqQoiSSBhBnnzaQjYyrZKNIY-PrR9hZkeTh8fl0=",
  saveWhackoScore: "/nSl1PInBA7l-tsmqYfWjVmtJJXlx1C3SqknyELZ3mwA=",
  saveMakeWordsScore: "/z3EgqHWgMvt46PIy-gHCYFgVdU4gQUSPiIEN9jE3pfM=",
  savePourDaScore: "/clo7XAvZbo-FEJVLI_AtpQ7wEENSxrN_leC7REdMFsQ=",
  saveDoodleMatchScore: "/ZDHQeOXoVxLjwatbaZVKu1AUd0ofOhJj8pDIVrbyC0s=",
  saveFieldMasterScore: "/EA1Yju5NBV0Fi3uiA9O12AOe3jhiWpQI0M__70TeTbI=",
  saveBatMasterScore: "/XujHDlowex0A0Lw93upDphgMy2xIuO_sOZzeP8MMjaI=",
  saveHexaDropScore: "/Cj-yz5L7cANpJDeVl731P20CZoUJ81jiDfEkNQ977tk=",
  saveLyricLingoScore: "/n1g-N7gaPv6eDEsIaK1D9jJ0HS16s_SnX4SqCk7CTis=",
  saveQuizzicalScore: "WznYose7OX_IWyNpXA1Ac2vWk_ABu6F2-zvk4JuRHvw=",
  getUserFeedbackForSchedule: "/2sdVa8m1dM3RWPnLGp19ElG03iKrPwCUtHVuBTuO94c=",
  saveGameFeedback: "/dQNuWyalyzOQ5yuhixOqwlSp5YbZZv4k6W4Imat0vj0=",
  claimFreebie: {
    addTableNumber: "/f5IuFbZ62WHFlz3GfK9UAOnF-4L46BSKWXGPe_rvuW0=",
    validateCodeStatus: "/C1YJZJ9P7Sss5jsHO-4eDrZywa7rRrQ_6AIYLYvf1hc=",
    validateCode: "/L86tfaIpcdr9Yw89UrQJQA9KOg4iF7L0malrRS0DU6A=",
    validateCongratsStatus: "/mn5-hYQI5Wel2DouGxPVrUjCWW2U_es2coJUInVfza8=",
  },
  voucher: {
    validateStatus: "/niw-k6cTF364E8kkiVBMy5QdmctjfSh9slTOd-1_gho=",
    sendCouponCode: "/RqYnoCSlE_LQeu7wL2k6SFXuGfQluOcHovdIHUMURJw=",
  },
  freebie: {
    getDetails: "/yPjl3H41iUJe_7Kzv5Fy0asi3lOSuy9EWY96Ihkimh8=",
    send: "/Zvjn4sBfgF04UcX-88-MF0EKBq_8icRnfpNB_vYntxM=",
    saveFeedback: "/2oiiiFn0VO88MiENzLtijItl6KfO4d-I92edEBQ_f5A=",
  },
  rewards: {
    getDetails: "/yjfA00nDv3bNna2teFO5rvV0Rf4qowXuDzK8ZeQPtis=",
  },
  upcRegistration: "/mqq2Kldl5aCP_Z8J_bGAKflb5pRjJMJoH1g4c9cyhSE=",
  miror: {
    checkPhone: "/check-miror-phone",
    login: "/tXkE8IzO-Xq3jUoNP2BSQrd2CFFP1se8JL8TpLnjRt4=",
    getLobbyDetails: "/hsH7GrIIuMCZDaJIkQViLEoGGncrT4vx6Ez6MW4UXoM=",
  },
};
