export default [
  {
    path: "/",
    name: "Home",
    meta: {
      requiresAuth: false,
    },
    component: () => import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
  },
  {
    path: "/:business",
    name: "Register",
    meta: {
      requiresAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: "register" */ "@/views/Register.vue"),
  },
  {
    path: "/:business/miror-auth",
    name: "MirorAuth",
    meta: {
      requiresAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: "mirorauth" */ "@/views/Miror/Auth.vue"),
  },
  {
    path: "/:business/lobby",
    name: "Lobby",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "lobby" */ "@/views/Lobby.vue"),
  },
  {
    path: "/:business/game/:schedule_id/:game/:redirect?/:game_session_id?",
    name: "Game",
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "game" */ "@/views/Game.vue"),
  },
  {
    path: "/:business/leaderboard/:schedule_id",
    name: "Leaderboard",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "leaderboard" */ "@/views/Leaderboard.vue"),
  },
  {
    path: "/:business/gameover/:schedule_id/:game_session_id",
    name: "GameOver",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "gameover" */ "@/views/GameOver.vue"),
  },
  {
    path: "/:business/table-number/:schedule_id",
    name: "TableNumber",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "tablenumber" */ "@/views/TableNumber.vue"),
  },
  {
    path: "/:business/claim-freebie/:schedule_id",
    name: "ClaimFreebie",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "claimfreebie" */ "@/views/ClaimFreebie.vue"),
  },
  {
    path: "/:business/congratulations/:schedule_id",
    name: "Congratulations",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "congratulations" */ "@/views/Congratulations.vue"
      ),
  },
  {
    path: "/:business/voucher/:schedule_id",
    name: "Voucher",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "voucher" */ "@/views/Voucher.vue"),
  },
  {
    path: "/:business/rewards",
    name: "Rewards",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "rewards" */ "@/views/Rewards.vue"),
  },
  {
    path: "/:business/gohomeda",
    name: "GoHomeDa",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "gohomeda" */ "@/views/Gohomeda.vue"),
  },
  {
    path: "/:business/limit-reached",
    name: "UserLimit",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "userlimit" */ "@/views/UserLimit.vue"),
  },
];
